import React from 'react';
import type { PlanRowProps } from '../types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

export const PlanRow: React.FC<PlanRowProps> = ({ val, handleClickShowDetail, showDetail }) => {
    const {
        plan_name,
        contract_term,
        base_rate,
        total,
        total_usage,
        rate,
        renewable,
        cancel_fee,
        plan_desc,
        terms_url,
        efl_url,
        enroll_url,
        enroll_phone
    } = val;

    return (
        <div className="plan-row">
            <div className="plan-content">
                <div className="plan-overview mb-4">
                    <div className="rep-logo">LOGO</div>
                    <div className="plan-info">
                        <div>{contract_term} Months</div>
                        <label>Plan Length</label>
                    </div>
                    <div className="plan-info">
                        <div>{(parseFloat(base_rate) * 100).toFixed(2)}¢</div>
                        <label>Base Rate</label>
                    </div>
                    <div className="plan-info">
                        <div>${total}</div>
                        <label>Based on {total_usage}kWh</label>
                    </div>
                </div>
                <div className="plan-name">{plan_name}</div>
                <div className="mt-3">
                    <Button
                        variant="outline-secondary"
                        onClick={() => {handleClickShowDetail()}}
                    >
                        <span className="me-2">{`${ showDetail ? 'Hide' : 'Show'} Detail`}</span>
                        <FontAwesomeIcon icon={showDetail ? faCaretUp : faCaretDown} />
                    </Button>
                </div>
                <div className={`plan-detail ${showDetail ? 'active' : ''}`}>
                    <div className="mt-4">{plan_desc}</div>
                    <div className="mt-4">
                        <div>Renewable Percentage: {renewable}%</div>
                        <div>Cancellation Fee: {cancel_fee}</div>
                    </div>
                    <div className="plan-links mt-3">
                        {
                            terms_url && <a href={terms_url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFilePdf} /><span className="ms-2">Terms of Service</span></a>
                        }
                        {
                            efl_url && <a href={efl_url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFilePdf} /><span className="ms-2">Facts Label</span></a>
                        }
                    </div>
                </div>
            </div>
            <div className="plan-options d-flex flex-column">
                <div className="plan-info">
                    <div className="plan-est-rate">{rate}¢</div>
                    <label>Total Est. Rate/kWh</label>
                </div>
                {
                    enroll_phone && enroll_phone !== '() -' &&
                    <div className="text-center" style={{color:'#666'}}>{enroll_phone}</div>
                }
                {
                    enroll_url &&
                    <div className="mt-5 d-flex justify-content-center">
                        <a href={enroll_url} target="_blank" rel="noreferrer" className="plan-enroll-btn">Sign Up</a>
                    </div>
                }
            </div>
        </div>
    )
}