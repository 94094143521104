import React, { useState, useEffect } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { CheckboxItem } from '../components/checkbox-item';
import {
    fetchRetailElectricProviders,
    newAgreement
} from '../api/api'
import qs from 'qs';
import type { Provider, GetInfoProps } from '../types'
import { toast } from 'react-toastify';

export const GetInfo: React.FC<GetInfoProps> = ({ handleShowAgreement }) => {
    const location = useLocation();
    const [ providers, setProviders ] = useState<Provider[]>([]);
    const [ loadingProviders, setLoadingProviders ] = useState(false);
    const [ validated, setValidated ] = useState(false);
    const [ selectedProvider, setSelectedProvider ] = useState<Provider|undefined>(undefined);
    const [ showProviderError, setShowProviderError ] = useState(false);
    const [ meterNumber, setMeterNumber ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ submitting, setSubmitting ] = useState(false);
    const [ isAgree, setIsAgree ] = useState(false);
    const [ forwardEmail, setForwardEmail ] = useState(false);
    
    useEffect(() => {
        setLoadingProviders(true)
        fetchRetailElectricProviders().then(res => {
            if (res) setProviders(res)
            setLoadingProviders(false)
        })
    }, [])

    const handleSubmit = (evt: React.FormEvent) => {
        evt.preventDefault();
        evt.stopPropagation();

        const esiid = qs.parse(location.search, { ignoreQueryPrefix: true }).esiid;
        const form = evt.currentTarget as HTMLInputElement;
        const validProvider = handleValidProvider()
        setValidated(true);
        if (!form.checkValidity() || !isAgree || !validProvider || !selectedProvider || !esiid || submitting) return;

        setSubmitting(true);
        newAgreement(
            esiid,
            meterNumber,
            selectedProvider.certificate_number,
            email,
            forwardEmail ? '1' : '0'
        ).then(res => {
            if (res && res.response && res.response.consent_id) {
                handleShowAgreement(res.response.consent_id, email);
            }
            else {
                if (res.error_code && res.error_code === '500') {
                    toast.error('Incorrect ESIID-METER-REP Combination');
                }
                setSubmitting(false);
                console.error('error: new agreement');
            }
        })
    }

    const handleValidProvider = () => {
        let isValid = true
        setShowProviderError(false)
        if (!selectedProvider){
            isValid = false
            setShowProviderError(true)
        }
        return isValid
    }

    const selectStyles = {
        control: (style: object) => ({
            ...style,
            borderColor: showProviderError ? '#dc3545 !important' : '#ced4da',
            borderRadius: '.3rem'
        }),
        singleValue: (style: object) => ({
            ...style,
            color: '#495057',
            fontSize: '1.25rem',
            lineHeight: 1.5
        }),
        valueContainer: (style: object) => ({
            ...style,
            padding: '7px 1rem'
        })
    }
    
    return (
        <div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Provider</Form.Label>
                    <Select
                        instanceId="repsSelect"
                        value={selectedProvider}
                        onChange={(obj: any) => {
                            setSelectedProvider(obj)
                            setShowProviderError(false)
                        }}
                        options={providers}
                        getOptionLabel={option => `${option.business_name}`}
                        getOptionValue={option => `${option.business_name}`}
                        isSearchable={true}
                        maxMenuHeight={200}
                        styles={selectStyles}
                        isLoading={loadingProviders}
                        isDisabled={submitting}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Meter Number</Form.Label>
                    <Form.Control
                        required
                        size="lg"
                        onChange={evt => setMeterNumber(evt.target.value)}
                        value={meterNumber}
                        disabled={submitting}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        required
                        size="lg"
                        type="email"
                        onChange={evt => setEmail(evt.target.value)}
                        value={email}
                        disabled={submitting}
                    />
                </Form.Group>
                <div className="checkbox-row">
                    <CheckboxItem
                        label="Would you like to receive a copy of the consent?"
                        value="1"
                        key="0"
                        checked={forwardEmail}
                        handleClick={() => {
                            setForwardEmail(!forwardEmail)
                        }}
                        disabled={submitting}
                    />
                </div>
                <div className="checkbox-row">
                    <CheckboxItem
                        label="By clicking on the box, I agree to grant EIQhome permission to access my account on Smart Meter Texas (SMT). In addition, EIQhome shall provide all SMT email communications received by EIQhome on your behalf to your email address provided."
                        value="1"
                        key="0"
                        checked={isAgree}
                        handleClick={() => {
                            setIsAgree(!isAgree)
                        }}
                        disabled={submitting}
                    />
                </div>
                <Form.Group className="mb-0 d-flex justify-content-end mt-5">
                    <Button type="submit" disabled={submitting}>
                        <span>Continue</span>
                        { submitting && <Spinner animation="border" variant="light" size="sm" style={{marginLeft:7}} /> }
                    </Button>
                </Form.Group>
            </Form>
        </div>
    )
}
