import React, { useEffect, useRef, useState } from 'react';
import he from 'he';
import type { ShowAgreementProps } from '../types'
import { getAgreement } from '../api/api'
import { toast } from 'react-toastify';
import { LoadingRow } from '../components/loading-row';

export const ShowAgreement: React.FC<ShowAgreementProps> = ({
    consentId,
    handleBack,
    handleSuccess,
    email
}) => {
    const agreementContent = useRef<HTMLDivElement>(null);
    const [ agreement, setAgreement ] = useState('');
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        if (consentId && !agreement) {
            handleCheckAgreement('get')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consentId, agreement])

    const handleCheckAgreement = (type: string) => {
        setLoading(true)
        getAgreement(consentId).then(res => {
            if (res) {
                if (res.error_code && res.error_code === '500') {
                    setLoading(false);
                    toast.error(res.message);
                    handleBack();
                    return;
                }
                const status = res.status.toLowerCase()
                if (status === 'new') {
                    setTimeout(() => {
                        handleCheckAgreement('get')
                    }, 2000);
                }
                if (status === 'waiting for approval') {
                    if (type === 'get') {
                        setAgreement(res.response.form_content)
                        setLoading(false);
                    }
                    else {
                        // type: confirm
                        setTimeout(() => {
                            handleCheckAgreement('confirm')
                        }, 2000);
                    }
                }
                if (status === 'approved') {
                    handleSuccess();
                }
            }
            else {
                setLoading(false);
                console.log('error: get agreement')
            }
        })
    }

    useEffect(() => {
        let timer = setInterval(() => {
            if (document.getElementById('action-required')) {
                const wrapper: any = document.getElementById('agreement-wrapper');
                const scrollTo: any = document.getElementById('action-required');
                const topPos = scrollTo.offsetParent.offsetParent.offsetTop;
                wrapper.scrollTop = topPos - 160;

                clearInterval(timer);
                if (typeof window !== 'undefined') {
                    window.scrollTo(0, 0);
                }
            }
        }, 300)
    
        return (() => {
            clearInterval(timer)
        })
    }, [agreement])

    const createMarkup = () => {
        let html = ''
        if (agreement) {
            html = he.decode(agreement)
            html = html.replace(/<i>ACTION REQUIRED:/, '<i id="action-required">ACTION REQUIRED:')
            html = html.replace(/<html><body>/, '').replace(/<\/body><\/html>/, '')
        }
        return {__html: html}
    }

    useEffect(() => {
        const confirmSmtRequest = (link: string) => {
            openLink(link);
            handleCheckAgreement('confirm');
        }
    
        const rejectSmtRequest = () => {
            toast.error('Unfortunately, Please apply again next time');
            handleBack()
        }
    
        const openLink = (link: string) => {
            if (typeof window !== 'undefined') {
                const w = window.open('about:blank')
                if (w) w.location.href = link
            }
        }
    
        const agreementListener = (evt: any) => {
            evt.preventDefault();
            let link = ''
            let btnText = ''
            const target = evt.target;
            if (target.tagName.toLowerCase() === 'b' && target.parentNode.tagName.toLowerCase() === 'a') {
                link = target.parentNode.href
                btnText = target.innerText.toLowerCase()
            }
            else if (target.tagName.toLowerCase() === 'a') {
                link = target.href
                btnText = target.innerText.toLowerCase()
            }
            if (link && btnText) {
                switch(btnText) {
                    case 'confirm': confirmSmtRequest(link); break;
                    case 'do not confirm': rejectSmtRequest(); break;
                    case 'did not request': rejectSmtRequest(); break;
                    default: openLink(link);
                }
            }
        }

        if (agreementContent && agreementContent.current) {
            const current = agreementContent.current
            current.addEventListener('click', agreementListener)
            return (() => {
                if (agreementContent && current) {
                    current.removeEventListener('click', agreementListener)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agreement])

    return (
        <div>
            {
                loading && <LoadingRow />
            }
            {
                (!loading && email) &&
                <div className="mb-3" style={{fontSize:14,color:'#666'}}><b className="me-4">To: {email}</b><br/>From: SMT</div>
            }
            <div
                id="agreement-wrapper"
                dangerouslySetInnerHTML={createMarkup()}
                ref={agreementContent}
                style={{opacity: loading ? 0 : 1}}
            />
        </div>
    )
}