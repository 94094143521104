import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt } from '@fortawesome/free-solid-svg-icons'
import type { UsageAdjustProps, PremiseDetail, PremiseAddress } from '../types';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const UsageAdjust: React.FC<UsageAdjustProps> = ({
    usage,
    disabled,
    handleChangeUsage,
    handleReset,
    premiseDetail
}) => {
    const [ values, setValues ] = useState<any>(undefined);

    useEffect(() => {
        if (usage) setValues(usage);
    }, [usage])

    const premiseInfo = () => {
        if (!premiseDetail) return null;
        const { address } = premiseDetail.address as PremiseAddress
        const { year_built, bathrooms, bedrooms, lot_size } = premiseDetail.detail as PremiseDetail
        return (
            <div className="premise-detail-row">
                <div className="mb-1" style={{fontWeight:700}}>Address: {address}</div>
                <div className="premise-detail">
                    { year_built && <div className="premise-detail-item">Year Built: {year_built}</div> }
                    { bathrooms && <div className="premise-detail-item">Bathrooms: {bathrooms}</div> }
                    { bedrooms && <div className="premise-detail-item">Bedrooms: {bedrooms}</div> }
                    { lot_size && <div className="premise-detail-item">SqFt: {lot_size}</div> }
                </div>
            </div>
        )
    }

    if (!values) return null;
    return (
        <div className="usage-adjust-section mb-5">
            <div className="d-flex align-item-center justify-content-between pt-3" style={{fontSize:20,fontWeight:700}}>
                <span>Your Estimated Usage</span>
                <div>
                    <Button variant="link" className="me-3" onClick={() => {handleReset()}} disabled={disabled}><FontAwesomeIcon icon={faUndoAlt} /><span className="ms-2">Reset</span></Button>
                    <Button onClick={() => {handleChangeUsage(values)}} disabled={disabled}>Confirm</Button>
                </div>
            </div>
            { premiseInfo() }
            <div className="usage-adjust-row">
                {
                    Object.keys(values).map((key) => (
                        <div className="usage-adjust-item ps-1 pe-1" key={key}>
                            <Form.Group>
                                <Form.Label>{months[parseInt(key) - 1]}</Form.Label>
                                <Form.Control
                                    required
                                    onChange={evt => {
                                        const val = evt.currentTarget.value;
                                        let newValues = Object.assign({}, values);
                                        newValues[key] = parseInt(val);
                                        setValues(newValues);
                                    }}
                                    value={values[key]}
                                    disabled={disabled}
                                />
                            </Form.Group>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}