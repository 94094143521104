import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { GetInfo } from './get_info';
import { ShowAgreement } from './show_agreement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import type { AccessSmtProps } from '../types'

export const AccessSmt: React.FC<AccessSmtProps> = ({
    show,
    handleClose,
    handleFetchActualUsage,
    handleSetConsentId
}) => {
    const [ view, setView ] = useState('info');  //  info | agreement
    const [ consentId, setConsentId ] = useState('');
    const [ email, setEmail ] = useState('');

    const getView = () => {
        switch (view) {
            case 'info':
                return <GetInfo
                            handleShowAgreement={(concentId: string, email: string) => {
                                setConsentId(concentId);
                                setEmail(email);
                                setView('agreement');
                                handleSetConsentId(concentId);
                            }}
                        />;
            case 'agreement':
                return <ShowAgreement
                            consentId={consentId}
                            handleBack={() => setView('info')}
                            email={email}
                            handleSuccess={() => {
                                resetAndClose();
                                handleFetchActualUsage();
                            }}
                        />;
            default: return null;
        }
    }

    const resetAndClose = () => {
        setView('info');
        setConsentId('');
        handleClose();
    }

    if (!show) return null;
    return (
        <div id="access-smt-wrapper" className={`${show ? 'active' : ''}`}>
            <div className="access-smt-main-body">
                <div className="position-absolute" style={{top:20,right:20}}>
                    <Button
                        className="close-btn"
                        variant="link"
                        onClick={() => resetAndClose()}
                        style={{fontSize:32,color:'#333'}}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </div>
                { getView() }
            </div>
            <div className="mask" onClick={() => resetAndClose()} />
        </div>
    )
}