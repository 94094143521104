import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import moment from 'moment';
import {
    fetchEstUsage,
    fetchDailyUsage,
    fetchMonthlyUsage,
    fetchIntervalUsage,
    fetchPlans,
    fetchFilterOptions,
    fetchPremiseDetail
} from '../api/api';
import { Button, Nav } from 'react-bootstrap';
import { toast } from 'react-toastify';
import type {
    SmtMonthlyReads,
    SmtDailyReads,
    SmtIntervalReads,
    PlanProps,
    FilterValues,
    Utilities,
    PremiseInfo
} from '../types';
import { PlanRow } from './plan_row';
import { CheckboxItem } from '../components/checkbox-item';
import { AccessSmt } from '../access_smt';
import { LoadingRow } from '../components/loading-row';
import { UsageAdjust } from './usage-adjust';

const filterDefaultValues = {
    utility_id: '',
    term: [],
    provider_id: [],
    renewable: false,
    prepaid: 'all',
    source: '',
    type: 'normal'
}

const prepaidOptions: Array<any> = [
    { label: 'All', value: 'all' },
    { label: 'Show Only Prepaid', value: 'true' },
    { label: 'Do Not Show Prepaid', value: 'false' }
]

export const Plans = () => {
    const location = useLocation();
    const { address, esiid, zipcode, city, state } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const [ usage, setUsage ] = useState<Object|undefined>(undefined);
    const [ freeWeekendUsage, setFreeWeekendUsage ] = useState(undefined);
    const [ free7DaysUsage, setFree7DaysUsage ] = useState(undefined);
    const [ freeNightUsage, setFreeNightUsage ] = useState(undefined);
    const [ loading, setLoading ] = useState(true);
    const [ initializeFilter, setInitializeFilter ] = useState(true);
    const [ plans, setPlans ] = useState<Array<PlanProps>>([]);
    const [ filterValues, setFilterValues ] = useState<FilterValues>(filterDefaultValues);
    const [ reps, setReps ] = useState([]);
    const [ terms, setTerms ] = useState([]);
    const [ source, setSource ] = useState([]);
    const [ utilities, setUtilities ] = useState<Array<Utilities>>([]);
    const [ availableTerms, setAvailableTerms ] = useState<Array<string>>([]);
    const [ availableReps, setAvailableReps ] = useState<Array<string>>([]);
    const [ unfoldPlanIds, setUnfoldPlanIds ] = useState<Array<string>>([]);
    const [ showAccessSmtSection, setShowAccessSmtSection ]  = useState(false);
    const [ consentId, setConsentId ] = useState('');
    const [ isEstMode, setIsEstMode ] = useState(true);
    const [ planType, setPlanType ] = useState('normal');
    const [ premiseDetail, setPremiseDetail ] = useState<PremiseInfo|undefined>(undefined);

    useEffect(() => {
        if (!address || !esiid || !zipcode || !city || !state) {
            toast.error('Param is missing');
            return;
        }

        getPremiseDetail();
        handleSwitchPlanType('normal');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getEstUsage = () => {
        setLoading(true);
        setUsage(undefined);
        fetchEstUsage(address, city, state, zipcode).then(res => {
            if (res && res.status === 1) {
                setUsage(res.usages);
            }
        })
    }

    const getPremiseDetail = () => {
        fetchPremiseDetail(zipcode, address).then(res => {
            if (res.status === 1) {
                setPremiseDetail(res.premise);
            }
        })
    }

    const getMonthlyUsage = () => {
        setLoading(true);
        setUsage(undefined);
        const startDate = moment().subtract(1, 'years').format('MM/DD/YYYY');
        const endDate = moment().subtract(1, 'days').format('MM/DD/YYYY');
        fetchMonthlyUsage(esiid, consentId, startDate, endDate).then(res => {
            if (res && res.response && res.response.reads) {
                const monthlyUsage = mergeMonthlyReads(res.response.reads);
                setUsage(monthlyUsage);
                setIsEstMode(false);
            }
        })
    }

    const getDailyUsage = () => {
        setLoading(true);
        setFreeWeekendUsage(undefined);
        setFree7DaysUsage(undefined);
        const startDate = moment().subtract(1, 'years').format('MM/DD/YYYY');
        const endDate = moment().subtract(1, 'days').format('MM/DD/YYYY');
        fetchDailyUsage(esiid, consentId, startDate, endDate).then(res => {
            if (res && res.response && res.response.reads) {
                const free7DaysAndWeekendUsage = mergeDailyReads(res.response.reads);
                setFree7DaysUsage(free7DaysAndWeekendUsage.free7Days);
                setFreeWeekendUsage(free7DaysAndWeekendUsage.freeWeekend);
            }
        })
    }

    const getIntervalUsage = () => {
        setLoading(true);
        setFreeNightUsage(undefined);
        const startDate = moment().subtract(1, 'years').format('MM/DD/YYYY');
        const endDate = moment().subtract(1, 'days').format('MM/DD/YYYY');
        fetchIntervalUsage(esiid, consentId, startDate, endDate).then(res => {
            if (res && res.response && res.response.reads) {
                const dayNightUsage = mergeIntervalReads(res.response.reads);
                setFreeNightUsage(dayNightUsage);
            }
        })
    }

    const mergeMonthlyReads = (data: Array<SmtMonthlyReads>) => {
        let usage: any = {}
        for (let i in data) {
            const key = moment(data[i].startDate, 'MM/DD/YYYY').format('M');
            usage[key] = usage[key] ? usage[key] + parseInt(data[i].actualkWh) : parseInt(data[i].actualkWh);
        }
        return usage;
    }

    const mergeDailyReads = (data: Array<SmtDailyReads>) => {
        let freeWeekend: any = {},
            free7Days: any = {},
            dailyUsage: any = [];
            
        for (let i in data) {
            const date = moment(data[i].readDate, 'MM/DD/YYYY');
            const m = date.format('M');
            const w = date.days();
            const objKey = (w === 0 || w === 6) ? 'free' : 'charge';
            const usage = parseFloat(data[i].energyDataKwh);
            
            if (!dailyUsage[m]) dailyUsage[m] = [];
            if (!freeWeekend[m]) freeWeekend[m] = {};
            
            dailyUsage[m].push(usage);
            freeWeekend[m][objKey] = freeWeekend[m][objKey] ? freeWeekend[m][objKey] + usage : usage
        }

        for (let m in dailyUsage) {
            const oneMonth = dailyUsage[m];
            oneMonth.sort((a: string, b: string) => {
                if (parseInt(a) < parseInt(b)) return 1;
                if (parseInt(a) > parseInt(b)) return -1;
                return 0;
            })
            oneMonth.forEach((val: number, idx: number) => {
                const objKey = (idx < 7) ? 'free' : 'charge';
                if (!free7Days[m]) free7Days[m] = {};
                free7Days[m][objKey] = free7Days[m][objKey] ? free7Days[m][objKey] + val : val;
            })
        }
        
        return { freeWeekend: freeWeekend, free7Days: free7Days };
    }

    const mergeIntervalReads = (data: Array<SmtIntervalReads>) => {
        let freeNight: any = {};
        for (let i in data) {
            const datetime = moment(data[i].datetime, 'MM/DD/YYYY HH:mm');
            const date = datetime.format('MM/DD/YYYY');
            const m = datetime.format('M');

            const objKey = (datetime.isSameOrAfter(`${date} 20:00`, 'minute') || datetime.isBefore(`${date} 05:59`, 'minute')) ? 'free' : 'charge';
            if (!freeNight[m]) freeNight[m] = {};
            freeNight[m][objKey] = freeNight[m][objKey] ? freeNight[m][objKey] + parseFloat(data[i].usage) : parseFloat(data[i].usage);
        }
        
        return freeNight;
    }

    useEffect(() => {
        if (planType === 'normal') {
            if (!usage) return;
            getNormalPlans();
            getFilterOptions('new');
        }
        if (planType === 'tou') {
            if (!freeWeekendUsage || !free7DaysUsage || !freeNightUsage) return;
            getTouPlans();
            getFilterOptions('new');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usage, freeWeekendUsage, free7DaysUsage, freeNightUsage])

    useEffect(() => {
        if (planType === 'normal') {
            if (!usage) return;
            getNormalPlans();
            getFilterOptions('update');
        }
        if (planType === 'tou') {
            if (!freeWeekendUsage || !free7DaysUsage || !freeNightUsage) return;
            getTouPlans();
            getFilterOptions('update');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues])

    const getNormalPlans = () => {
        setLoading(true);
        setPlans([]);
        setUnfoldPlanIds([]);
        fetchPlans(planType, usage, zipcode, filterValues).then(res => {
            setLoading(false);
            if (res && res.response && res.response.plans) {
                setPlans(res.response.plans);
            }
            else {
                if (res.error_code === '500') {
                    setPlans([])
                }
            }
        })
    }

    const getTouPlans = async () => {
        setLoading(true);
        setPlans([]);
        setUnfoldPlanIds([]);
        let plans: Array<PlanProps> = [];

        await fetchPlans('night', freeNightUsage, zipcode, filterValues).then(res => {
            if (res && res.response && res.response.plans) {
                plans = plans.concat(res.response.plans);
            }
        })
        await fetchPlans('weekend', freeWeekendUsage, zipcode, filterValues).then(res => {
            if (res && res.response && res.response.plans) {
                plans = plans.concat(res.response.plans);
            }
        })
        await fetchPlans('7days', free7DaysUsage, zipcode, filterValues).then(res => {
            if (res && res.response && res.response.plans) {
                plans = plans.concat(res.response.plans);
            }
        })
        setLoading(false);
        setPlans(plans);
    }

    const getFilterOptions = (type: string) => {
        fetchFilterOptions(planType, zipcode, filterValues).then(res => {
            if (res && res.response) {
                let { reps, terms, source, utilities } = res.response;
                let newAvailableTerms = [],
                    newAvailableReps = [];
                if (type === 'new') {
                    setReps(reps);
                    terms.sort((a: string, b: string) => {
                        if (parseInt(a) < parseInt(b)) return -1;
                        if (parseInt(a) > parseInt(b)) return 1;
                        return 0;
                    })
                    setTerms(terms);
                    setSource(source);
                    setUtilities(utilities);
                    setInitializeFilter(false);
                }
                for (let i in reps) {
                    newAvailableReps.push(reps[i].provider_id);
                }
                for (let i in terms) {
                    newAvailableTerms.push(terms[i]);
                }
                setAvailableTerms(newAvailableTerms);
                setAvailableReps(newAvailableReps);
            }
            else {
                if (res.error_code === '500') {
                    setAvailableTerms([]);
                    setAvailableReps([]);
                }
            }
        })
    }

    const handleSwitchPlanType = (type: string) => {
        if (type === 'tou' && !consentId) {
            toast.error('Click "GET MY USAGE" and proceed to find out free energy plans.')
            return;
        }
        setPlanType(type);
        let newFilterValues = Object.assign({}, filterValues);
        newFilterValues.type = type;
        setFilterValues(newFilterValues);
        setInitializeFilter(true);
        if (type === 'normal') {
            isEstMode ? getEstUsage() : getMonthlyUsage();
        }
        else {
            getDailyUsage();
            getIntervalUsage();
        }
    }

    return (
        <div>
            <div className="plan-type-options pt-4">
                <Nav
                    variant="tabs"
                    activeKey={planType}
                    className="justify-content-center"
                    style={{width:'100%'}}
                    onSelect={(eventKey: any) => {
                        if (eventKey === planType || loading) return;
                        handleSwitchPlanType(eventKey);
                    }}
                >
                    <Nav.Item>
                        <Nav.Link eventKey="normal" disabled={loading}>Regular Plans</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="tou" disabled={loading}>Free Energy Plans</Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
            <div id="plans-wrapper">
                <div id="plans-aside">
                    <div className="plans-filter">
                        <div className="plans-filter-title">Terms</div>
                        {
                            initializeFilter && <LoadingRow size="sm" />
                        }
                        {
                            !initializeFilter &&
                            <div className="plans-filter-options">
                                <CheckboxItem
                                    label="All"
                                    value="-1"
                                    key={-1}
                                    checked={filterValues.term.length === 0}
                                    handleClick={() => {
                                        let newFilterValues = Object.assign({}, filterValues);
                                        newFilterValues.term = [];
                                        setFilterValues(newFilterValues);
                                    }}
                                    disabled={loading}
                                />
                                {
                                    terms.map((val, idx) => (
                                        <CheckboxItem
                                            label={`${val} Month`}
                                            value={val}
                                            key={idx}
                                            checked={filterValues.term.indexOf(val) >= 0}
                                            handleClick={(val: string) => {
                                                let newFilterValues = Object.assign({}, filterValues);
                                                const idx = (newFilterValues.term).indexOf(val)
                                                if (idx >= 0) {
                                                    newFilterValues.term.splice(idx, 1)
                                                }
                                                else {
                                                    newFilterValues.term.push(val)
                                                }
                                                setFilterValues(newFilterValues)
                                            }}
                                            onlyread={availableTerms.indexOf(val) < 0}
                                            disabled={loading}
                                        />
                                    ))
                                }
                            </div>
                        }
                    </div>
                    <div className="plans-filter">
                        <div className="plans-filter-title">Providers</div>
                        {
                            initializeFilter && <LoadingRow size="sm" />
                        }
                        {
                            !initializeFilter &&
                            <div className="plans-filter-options">
                                <CheckboxItem
                                    label="All"
                                    value="-1"
                                    key={-1}
                                    checked={filterValues.provider_id.length === 0}
                                    handleClick={() => {
                                        let newFilterValues = Object.assign({}, filterValues);
                                        newFilterValues.provider_id = [];
                                        setFilterValues(newFilterValues);
                                    }}
                                    disabled={loading}
                                />
                                {
                                    reps.map((val: any, idx) => (
                                        <CheckboxItem
                                            label={val.name}
                                            value={val.provider_id}
                                            key={idx}
                                            checked={filterValues.provider_id.indexOf(val.provider_id) >= 0}
                                            handleClick={(val: string) => {
                                                let newFilterValues = Object.assign({}, filterValues);
                                                const idx = (newFilterValues.provider_id).indexOf(val)
                                                if (idx >= 0) {
                                                    newFilterValues.provider_id.splice(idx, 1)
                                                }
                                                else {
                                                    newFilterValues.provider_id.push(val)
                                                }
                                                setFilterValues(newFilterValues)
                                            }}
                                            onlyread={availableReps.indexOf(val.provider_id) < 0}
                                            disabled={loading}
                                        />
                                    ))
                                }
                            </div>
                        }
                    </div>
                    <div className="plans-filter">
                        <div className="plans-filter-title">Renewable</div>
                        {
                            initializeFilter && <LoadingRow size="sm" />
                        }
                        {
                            !initializeFilter &&
                            <div className="plans-filter-options">
                                <CheckboxItem
                                    label="Show 100% Green Plans"
                                    value="-1"
                                    key={-1}
                                    checked={filterValues.renewable}
                                    handleClick={() => {
                                        let newFilterValues = Object.assign({}, filterValues);
                                        newFilterValues.renewable = !newFilterValues.renewable;
                                        setFilterValues(newFilterValues);
                                    }}
                                    disabled={loading}
                                />
                            </div>
                        }
                    </div>
                    <div className="plans-filter">
                        <div className="plans-filter-title">Prepaid</div>
                        {
                            initializeFilter && <LoadingRow size="sm" />
                        }
                        {
                            !initializeFilter &&
                            <div className="plans-filter-options">
                                {
                                    prepaidOptions.map((val, idx) => (
                                        <CheckboxItem
                                            label={val.label}
                                            value={val.value}
                                            key={idx}
                                            checked={filterValues.prepaid === val.value}
                                            handleClick={() => {
                                                let newFilterValues = Object.assign({}, filterValues);
                                                newFilterValues.prepaid = val.value;
                                                setFilterValues(newFilterValues);
                                            }}
                                            disabled={loading}
                                            isRadio={true}
                                        />
                                    ))
                                }
                            </div>
                        }
                    </div>
                    <div className="plans-filter">
                        <div className="plans-filter-title">Source</div>
                        {
                            initializeFilter && <LoadingRow size="sm" />
                        }
                        {
                            !initializeFilter &&
                            <div className="plans-filter-options">
                                <CheckboxItem
                                    label="All"
                                    value="-1"
                                    key="-1"
                                    checked={!filterValues.source}
                                    handleClick={() => {
                                        let newFilterValues = Object.assign({}, filterValues);
                                        newFilterValues.source = '';
                                        setFilterValues(newFilterValues);
                                    }}
                                    disabled={loading}
                                    isRadio={true}
                                />
                                <CheckboxItem
                                    label="Retail Energy Providers"
                                    value="rep"
                                    key="-2"
                                    checked={filterValues.source === 'rep'}
                                    handleClick={() => {
                                        let newFilterValues = Object.assign({}, filterValues);
                                        newFilterValues.source = 'rep';
                                        setFilterValues(newFilterValues);
                                    }}
                                    disabled={loading}
                                    isRadio={true}
                                />
                                {
                                    source.map((val, idx) => (
                                        <CheckboxItem
                                            label={val}
                                            value={val}
                                            key={idx}
                                            checked={filterValues.source === val}
                                            handleClick={() => {
                                                let newFilterValues = Object.assign({}, filterValues);
                                                newFilterValues.source = val;
                                                setFilterValues(newFilterValues);
                                            }}
                                            disabled={loading}
                                            isRadio={true}
                                        />
                                    ))
                                }
                            </div>
                        }
                    </div>
                    <div className="plans-filter">
                        <div className="plans-filter-title">Utilities</div>
                        {
                            initializeFilter && <LoadingRow size="sm" />
                        }
                        {
                            !initializeFilter &&
                            <div className="plans-filter-options">
                                {
                                    utilities.map((val, idx) => (
                                        <CheckboxItem
                                            label={val.utility_name}
                                            value={val.utility_id}
                                            key={idx}
                                            checked={filterValues.utility_id !== '' ? filterValues.utility_id === val.utility_id : true}
                                            handleClick={() => {
                                                let newFilterValues = Object.assign({}, filterValues);
                                                newFilterValues.utility_id = val.utility_id;
                                                setFilterValues(newFilterValues);
                                            }}
                                            disabled={loading}
                                        />
                                    ))
                                }
                            </div>
                        }
                    </div>
                </div>
                <div id="plans-list">
                    {
                        isEstMode &&
                        <UsageAdjust
                            usage={usage}
                            disabled={loading}
                            handleChangeUsage={(val: Object) => {
                                setUsage(val);
                            }}
                            handleReset={() => {
                                getEstUsage();
                            }}
                            premiseDetail={premiseDetail}
                        />
                    }
                    <div className="d-flex align-items-center justify-content-between" style={{paddingTop:24,color:'#999'}}>
                        <div>Total Plans: {plans.length}</div>
                        {
                            isEstMode &&
                            <Button variant="warning" style={{fontWeight:700}} onClick={() => setShowAccessSmtSection(true)}>GET MY USAGE</Button>
                        }
                        {
                            !isEstMode && consentId &&
                            <div className="text-end">
                                <p className="mt-0 mb-0" style={{color:'#666'}}>The plans below are calculated with actual usage</p>
                                <p className="mt-0 mb-0" style={{fontSize:13}}>Consent id: {consentId}</p>
                            </div>
                        }
                    </div>
                    {
                        loading && <LoadingRow />
                    }
                    {
                        !loading && plans &&
                        plans.map((val: PlanProps, idx) =>
                            <PlanRow
                                key={idx}
                                val={val}
                                showDetail={unfoldPlanIds.indexOf(idx.toString()) >= 0}
                                handleClickShowDetail={() => {
                                    let [ ...newUnfoldPlanIds ] = unfoldPlanIds;
                                    const position = newUnfoldPlanIds.indexOf(idx.toString());
                                    if (position >= 0) {
                                        newUnfoldPlanIds.splice(position, 1)
                                    }
                                    else {
                                        newUnfoldPlanIds.push(idx.toString());
                                    }
                                    setUnfoldPlanIds(newUnfoldPlanIds)
                                }}
                            />
                        )
                    }
                </div>
            </div>
            <AccessSmt
                show={showAccessSmtSection}
                handleClose={() => setShowAccessSmtSection(false)}
                handleFetchActualUsage={() => getMonthlyUsage()}
                handleSetConsentId={(id: string) => setConsentId(id)}
            />
        </div>
    )
}