import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AnimatedSwitch, spring } from 'react-router-transition';
import { ValidateAddress } from './validate_address';
import { Plans } from './plans';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
	const glide = (val: number) => {
		return spring(
			val, {
				stiffness: 174,
				damping: 24
			}
		)
	}

	return (
		<Router>
			<AnimatedSwitch
				atEnter={{offset:100}}
				atLeave={{offset:glide(-100)}}
				atActive={{offset:glide(0)}}
				className="page-wrapper"
				mapStyles={styles => ({
					transform: `translateX(${styles.offset}%)`,
				})}
			>
				<Route exact path="/" component={ValidateAddress} />
				<Route exact path="/plans" component={Plans} />
			</AnimatedSwitch>
			<ToastContainer />
		</Router>
	)
}

export default App;
