import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import type { Address } from './types'
import { fetchAddress } from './api/api'

export const ValidateAddress = () => {
    const history = useHistory();
    const [ searchingAddress, setSearchingAddress ] = useState<boolean>(false);
    const [ addressOptions, setAddressOptions ] = useState<Address[]>([]);
    const [ selectedAddress, setSelectedAddress ] = useState<Address[]>([]);
    
    const handleSearch = (val:string) => {
        setSearchingAddress(true)
        fetchAddress(val).then(data => {
            setAddressOptions(data);
            setSearchingAddress(false)
        })
    }

	const getStarted = () => {
        if (selectedAddress.length === 0) return;
        const address = selectedAddress[0]
        const { street, esiid, zipcode, city, state } = address
        history.push(`/plans?address=${street}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}`);
	}

    return (
        <div className="main-body">
            <div id="autocomplete-wrapper">
                <div className="position-relative">
                    <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        className="position-absolute"
                        style={{zIndex:1,top:12,left:12,fontSize:22,color:'#666'}}
                    />
                    <AsyncTypeahead
                        filterBy={() => true}
                        id="autocomplete"
                        isLoading={searchingAddress}
                        labelKey={(option:Address) => option.label}
                        minLength={1}
                        onSearch={handleSearch}
                        options={addressOptions}
                        placeholder="Please Enter Your Address..."
                        size="lg"
                        onChange={setSelectedAddress}
                    />
                </div>
                <Button
                    onClick={getStarted}
                    disabled={!selectedAddress[0]}
                    style={{marginTop:24}}
                >
                    Get Started
                </Button>
            </div>
        </div>
    )
}