import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faSquare, faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons'
import type { CheckboxItemProps } from '../types'

export const CheckboxItem: React.FC<CheckboxItemProps> = ({
    label,
    value,
    checked,
    handleClick,
    onlyread,
    disabled,
    isRadio
}) => {
    if (value === '0') return null;
    return (
        <div
            className={`checkbox-item ${onlyread ? 'onlyread' : ''}`}
            onClick={() => { if (!disabled && !onlyread) handleClick(value); }}
        >
            {
                checked ?
                <FontAwesomeIcon icon={isRadio ? faCheckCircle : faCheckSquare} style={{color:'#0d6efd',fontSize:20}} /> :
                <FontAwesomeIcon icon={isRadio ? faCircle : faSquare} style={{color:'#eee',fontSize:20}} />
            }
            <span>{label}</span>
        </div>
    )
}